#thankyou-confirmation-image-wrap{
    width: 45%;
    text-align: center;
    margin: auto;
    margin-top: 150px;
}

#thankyou-confirmation-image-wrap>img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}


@media screen and (max-width: 767px) {
#thankyou-confirmation-image-wrap{
    width: 95%;

}
}