#contact-form-top-color-block {
  height: 11px;
  flex-shrink: 0;
  background: rgba(3, 3, 3, 0.72);
}

#contact-form-navigation-header {
  height: 56px;
  flex-shrink: 0;
  background: #132937;
}

#contact-form-navigation-header {
  color: white;
  text-align: left;
}

#contact-form-navigation-header > :nth-child(1) {
  font-size: 30px;
  margin-top: 10px;
  margin-left: 16px;
}

#contact-form-navigation-header > :nth-child(2) {
  font-size: 25px;
  float: right;
  margin-top: 10px;
  margin-right: 16px;
}

#contact-form-wrap {
  width: 80%;
  /* height: 542px; */
  flex-shrink: 0;
  border-radius: 12px;
  background: #f7f1ea;
  box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.12);
  margin: auto;
  margin-top: 16px;
  padding-left: 21px;
  padding-right: 21px;
  padding-top: 23px;
  padding-bottom: 35px;
  font-family: "Raleway", sans-serif;
}

#contact-form-project-title {
  color: #070710;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 150% */
  letter-spacing: 0.25px;
  text-align: left;
  margin-top: 15px;
}

#contact-form-project-description {
  text-align: left;
  margin-top: 6px;
  color: rgba(3, 3, 3, 0.63);
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 143.2%; /* 15.752px */
  letter-spacing: 0.88px;
}

#contact-form-name-label{
    color: #070710;
font-family: "Poppins";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 166.667% */
letter-spacing: 0.25px;
text-align: left;
}

#contact-form-name-input{
    border-radius: 3px;
background: #FFF;
height: 36px;
width: 95%;
border: none;
margin-top: 7px;
}

#contact-form-element-wrap{
    margin-top: 36px;
}

#contact-form-email-label{
    color: #070710;
font-family: "Poppins";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 166.667% */
letter-spacing: 0.25px;
text-align: left;
}

#contact-form-email-input{
    border-radius: 3px;
background: #FFF;
height: 36px;
width: 95%;
border: none;
margin-top: 7px;
}

#contact-form-email-label-wrap{
    margin-top: 13px;
}

#contact-form-phone-label{
    color: #070710;
font-family: "Poppins";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 166.667% */
letter-spacing: 0.25px;
text-align: left;
}

#contact-form-phone-input{
    border-radius: 3px;
background: #FFF;
height: 36px;
width: 95%;
border: none;
margin-top: 7px;
}

#contact-form-phone-label-wrap{
    margin-top: 13px;
}

#contact-form-count-label{
    color: #070710;
font-family: "Poppins";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 166.667% */
letter-spacing: 0.25px;
text-align: left;
}

#contact-form-count-input{
    border-radius: 3px;
background: #FFF;
height: 36px;
width: 95%;
border: none;
margin-top: 7px;
}

#contact-form-count-label-wrap{
    margin-top: 13px;
}

#contact-form-submit-button{
    color: #FFF;
font-family: "Raleway";
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 153.846% */
letter-spacing: 0.25px;
text-transform: capitalize;
border-radius: 3px;
border: 0.5px solid #132937;
background: #132937;
box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.04);
height: 40px;
width: 95%;
margin-top: 29px;
cursor: pointer;
}

#contact-form-phone-number-contact-wrap{
  display: flex;
      color: #070710;
font-family: "Poppins";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 166.667% */
letter-spacing: 0.25px;
justify-content: right;
}

#contact-form-phone-number-contact-wrap>div{
margin-left: 15px;
}

#contact-form-phone-number-contact-wrap>:nth-child(1){
  margin-top: 3px;
}